import React from 'react';
import { AutocompleteInput, AutocompleteArrayInput, BooleanField, BooleanInput,
         ChipField, Create, Datagrid, DateField, DateTimeInput, DeleteButton,
         Edit, EditButton, Filter, FunctionField, ImageField, List,
         NumberField, NumberInput, ReferenceArrayInput, ReferenceArrayField,
         ReferenceField, ReferenceInput, ReferenceManyField, RichTextField,
         SelectInput, Show, ShowController, ShowView, SimpleForm,
         SingleFieldList, Tab, TabbedShowLayout, TextField,
         TextInput } from 'react-admin';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import RichTextInput from 'ra-input-rich-text';
import Moment from 'react-moment';

import toolbarOpts from 'apicrud-ui/lib/constants';
import { BottombarSaveOnly, ListPagination, MenuTitle } from 'apicrud-ui/lib/ra-custom';
import { EventLocationMap } from 'apicrud-ui/lib/mapbox';
import { validateInteger, validatePositive, validateRequired255String,
	 validateRequired4096String, validateSelected } from 'apicrud-ui/lib/validate';

const declinedFilter = {response: 'no', status: 'active'}
const guestFilter = {response: ['yes', 'considering-it', 'remind-me'],
      status: 'active'}
const invitedFilter = {response: null, status: 'active'}

export const eventCreate = props => (
    <Create {...props}>
      <SimpleForm>
        <TextInput source='name' validate={validateRequired255String} />
        <RichTextInput source='description' toolbar={toolbarOpts}
                 label='Description' validate={validateRequired4096String} />
        <ReferenceInput source="location_id" reference="location" perPage={40}
                 validate={validateSelected} >
            {/* TODO remove workaround - https://github.com/marmelab/react-admin/issues/6715
            <AutocompleteInput optionText={locationRenderer} /> */}
            <AutocompleteInput optionText='name' />
        </ReferenceInput>
        <DateTimeInput source='starts' />
        <AutocompleteInput source='duration' choices={[
            { id: 0, name: 'open' },
            { id: 30, name: '30 minutes' },
            { id: 60, name: '1 hour' },
            { id: 120, name: '2 hours' },
            { id: 180, name: '3 hours' },
            { id: 240, name: '4 hours' },
            { id: 360, name: '6 hours' },
        ]} defaultValue={0} />
        <ReferenceArrayInput label='Hosts' source='hosts' reference='person'
              perPage={255} >
          <AutocompleteArrayInput optionText='name' />
        </ReferenceArrayInput>
        <NumberInput source='capacity' defaultValue={0} label='Maximum guests'
                     validate={validateInteger} />
        <NumberInput source='guestpartymax' label='Guest party limit'
                     defaultValue={4} validate={validatePositive} />
        <BooleanInput source='rsvps_active' defaultValue={true}
                     label='RSVPs Active' />
        <DateTimeInput source='rsvp_start' showTime label='RSVPs start'
                     defaultValue={new Date()} />
        <DateTimeInput source='rsvp_by' showTime label='RSVPs end' />
        {/*
        <ReferenceInput source='theme_id' reference='theme' >
          <SelectInput optionText='name' />
        </ReferenceInput>
         */}
        <AutocompleteInput source='privacy' choices={[
            { id: 'public', name: 'public' },
            { id: 'invitee', name: 'invitee' },
            { id: 'secret', name: 'secret' },
        ]} defaultValue='public' />
        <ReferenceInput source="category_id" reference="category" >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput disabled source='status' defaultValue='active' />
      </SimpleForm>
    </Create>
);

export const eventEdit = props => (
    <Edit {...props} title={<MenuTitle />} >
      <SimpleForm toolbar={<BottombarSaveOnly />}>
        <TextInput source='name' validate={validateRequired255String} />
        <RichTextInput source='description' toolbar={toolbarOpts}
                 label='Description' validate={validateRequired4096String} />
        <ReferenceInput source="location_id" reference="location" perPage={40}
                 validate={validateSelected} >
            {/* TODO remove workaround - https://github.com/marmelab/react-admin/issues/6715
            <AutocompleteInput optionText={locationRenderer} /> */}
            <AutocompleteInput optionText='name' />
        </ReferenceInput>
        <DateTimeInput source='starts' />
        <AutocompleteInput source='duration' choices={[
            { id: 0, name: 'open' },
            { id: 30, name: '30 minutes' },
            { id: 60, name: '1 hour' },
            { id: 120, name: '2 hours' },
            { id: 180, name: '3 hours' },
            { id: 240, name: '4 hours' },
            { id: 360, name: '6 hours' },
        ]} defaultValue={0} />
        <ReferenceArrayInput label='Hosts' source='hosts' reference='person'
              perPage={255} >
          <AutocompleteArrayInput optionText='name' />
        </ReferenceArrayInput>
        <NumberInput source='capacity' label='Maximum guests'
                     validate={validateInteger} />
        <NumberInput source='guestpartymax' label='Guest party limit'
                     validate={validatePositive} />
        <BooleanInput source='rsvps_active' label='RSVPs Active' />
        <DateTimeInput source='rsvp_start' showTime label='RSVPs Start' />
        <DateTimeInput source='rsvp_by' showTime label='RSVPs End' />
        {/*
        <ReferenceInput source='theme_id' reference='theme'>
          <SelectInput optionText='name' />
        </ReferenceInput>
         */}
        <SelectInput source='comments_status' choices={[
            { id: 'active', name: 'active' },
            // { id: 'moderated', name: 'moderated' },
            { id: 'disabled', name: 'disabled' },
        ]} />
        <AutocompleteInput source='privacy' choices={[
            { id: 'public', name: 'public' },
            { id: 'invitee', name: 'invitee' },
            { id: 'secret', name: 'secret' },
        ]} />
        <ReferenceInput source="category_id" reference="category">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <AutocompleteInput source='status' choices={[
            { id: 'active', name: 'active' },
            { id: 'canceled', name: 'canceled' },
            { id: 'disabled', name: 'disabled' },
        ]} />
      </SimpleForm>
    </Edit>
);

const EventShowTabs = ({record, ...props}) => {
    // Is there a better way to pass these values than global vars??
    sessionStorage.setItem('guestpartymax', record.guestpartymax);
    return <Show title={<MenuTitle />} {...props} >
      <TabbedShowLayout>
        {record.rbac && record.rbac.includes('j') &&
        <Tab label='RSVP'>
            <ReferenceManyField reference='guest' target='event_id'
                    filter={{
			id: sessionStorage.getItem('guest_id'),
			event_id: props.id,
			uid: sessionStorage.getItem('uid')}}
                    fullWidth addLabel={false} >
                <Datagrid rowClick='edit'>
                    <EditGuestButton />
                    <TextField source='response' label='Response' emptyText=' ' />
                    <FunctionField label='Plus' render={record =>
                     `${record.partysize > 1 ? '+' +
                      (record.partysize - 1).toString() : ''}`}
                     />
                    <TextField source='remarks' emptyText=' ' />
                </Datagrid>
            </ReferenceManyField>
        </Tab>}

        <Tab label='guests'>
            <ReferenceManyField reference='guest' target='event_id'
                    filter={guestFilter} fullWidth perPage={20}
                    addLabel={false} pagination={<ListPagination />}>
                <Datagrid >
                    <ReferenceField source='uid' reference='person'
                            label='Guest' link='show'>
                        <TextField source='name' />
                    </ReferenceField>
                    <TextField source='response' label='RSVP' />
                    <FunctionField label='Plus' render={record =>
                     `${record.partysize > 1 ? '+' +
                      (record.partysize - 1).toString() : ''}`}
                     />
	            <TextField source='remarks' emptyText=' ' />
                    {record.rbac && record.rbac.includes('h')
			&& <EditButton />}
                </Datagrid>
            </ReferenceManyField>
        </Tab>

        <Tab label='discussion'>
          <ReferenceManyField reference='message' target='event_id'
                    addLabel={false} fullWidth perPage={20}
                    sort={{ field: 'created', order: 'DESC'}} >
                <Datagrid rowClick='show' expand={<MessageDisplay />} >
                    <ReferenceField source='sender_id' reference='person'
                            sortBy='created' link='show' >
                        <TextField source='name' />
                    </ReferenceField>
                    <TextField source='subject' />
                    <FunctionField source='created' render={record =>
                        <Moment fromNow>{record.created}</Moment>} />}
                </Datagrid>
            </ReferenceManyField>
            {record.rbac && record.rbac.includes('i') &&
                 <CreateMessageButton />}
        </Tab>

        <Tab label='Pictures' >
            <ReferenceManyField reference='album' target='event_id' 
                    addLabel={false}>
                <Datagrid rowClick='show'>
                    <ReferenceField source='uid' reference='person'
                            label='Added By' link={false} >
                        <TextField source='name' />
                    </ReferenceField>
                    <ReferenceField label='Cover' source='cover_id'
                        reference='picture' link={false} >
                      <ImageField source='thumbnail50x50' />
                    </ReferenceField>
                    <TextField source='name' label='Album' />
                </Datagrid>
            </ReferenceManyField>
            <CreateAlbumButton />
        </Tab>

        <Tab label='invited'>
            {/* TODO: sorting */}
            <ReferenceManyField reference='guest' target='event_id'
                    filter={invitedFilter} perPage={20}
                    addLabel={false} pagination={<ListPagination />}>
                <Datagrid >
                    <ReferenceField source='uid' reference='person'
                            label='Invitee' link='show' >
                        <TextField source='name' />
                    </ReferenceField>
                    {record.rbac && record.rbac.includes('h') &&
                    <FunctionField source='viewed' render={record =>
                        record.viewed &&
		        <Moment fromNow>{record.viewed}</Moment>} />}
                    {record.rbac && record.rbac.includes('h')
                        && <EditButton />}
                </Datagrid>
            </ReferenceManyField>
            {record.rbac && record.rbac.includes('h')
                && <CreateGuestButton />}
        </Tab>

        <Tab label='declined'>
            <ReferenceManyField reference='guest' target='event_id'
                    filter={declinedFilter} fullWidth perPage={20}
                    addLabel={false} pagination={<ListPagination />}>
                <Datagrid >
                    <ReferenceField source='uid' reference='person'
                            label='Invitee' link='show'>
                        <TextField source='name' />
                    </ReferenceField>
	            <TextField source='remarks' emptyText=' ' />
                    {record.rbac && record.rbac.includes('h')
			&& <DeleteButton />}
                </Datagrid>
            </ReferenceManyField>
        </Tab>

        <Tab label='hosts'>
            <ReferenceArrayField source='hosts' reference='person'>
                <SingleFieldList linkType='show'>
                    <ChipField source='name'/>
                </SingleFieldList>
            </ReferenceArrayField>
        </Tab>

        <Tab label='details'>
          <RichTextField source='description' />
	  <ReferenceArrayField source='hosts' reference='person'>
	      <SingleFieldList linkType='show'>
		  <ChipField source='name' />
	      </SingleFieldList>
	  </ReferenceArrayField>
          <ReferenceField source='location_id' reference='location'
                 link='show'>
             <FunctionField render={record =>
              `${record.address}, ${record.city}`} />
          </ReferenceField>
          <DateField source='starts' showTime />
          <NumberField source='duration' />
          <NumberField source='capacity' label='Maximum Guests' />
          <NumberField source='guestpartymax' label='Max Party Size' />
          <BooleanField source='rsvps_active' label='RSVPs Active' />
          <DateField source='rsvp_start' showTime label='RSVPs Start' />
          <DateField source='rsvp_by' showTime label='RSVPs End' />
          <TextField source='category' />
        </Tab>
      </TabbedShowLayout>
    </Show>;
};

// TODO get rid of whitespace above event header
// TODO event-get API called 4 times, should be only once
export const eventShow = props => {
    return <span>
      <Grid container spacing={1}><Grid item xs={4} md={8}>
        <Card>
        <ShowController {...props}>
            {controllerProps =>
             <ShowView actions={null} title=' ' {...props} {...controllerProps}>
                 <EventHeader {...props} />
             </ShowView>}
        </ShowController>
        </Card>
      </Grid><Grid item xs={4} md={4}>
        <Card>
            <EventLocationMap {...props} />
        </Card>
      </Grid><Grid item xs={12} md={12}>
        <ShowController {...props}>
            {controllerProps =>
	     <ShowView actions={null} title=' ' {...props} {...controllerProps}>
		 <EventShowTabs {...props} />
	     </ShowView>}
        </ShowController>
      </Grid></Grid>
    </span>;
};

export const eventList = props => (
    <List {...props} exporter={false} filters={<ListFilter />}
            filterDefaultValues={{prev: false}} bulkActionButtons={false}
            sort={{ field: 'starts', order: 'ASC'}}>
      <Datagrid rowClick='show'>
        <TextField source='name' />
        <DateField source='starts' showTime />
        <NumberField source='duration' />
        <BooleanField source='rsvps_active' label='RSVPs Active'/>
        <ChipField source='privacy' />
        <ChipField source='owner' sortable={false} />
        <ChipField source='category' sortable={false} />
      </Datagrid>
    </List>
);

const locationRenderer = record => {
    if (!record) {
	return '';
    }
    else if (record.name) {
	return `${record.name}, ${record.city}`;
    }
    else if (record.address) {
	return `${record.address}, ${record.city}`;
    }
    return `${record.city} (${record.id})`
}

const EventHeader = ({record, ...props}) => {
    return (
         <div style={{ backgroundColor: '#E0E0E0' }} >
          <CardHeader title=<EventCardTitle {...record} />
               titleTypographyProps={{align: 'center', variant: 'subtitle1'}} />
            {record && (
                <div style={{ backgroundColor: 
                        record.status === 'canceled' ? '#FF9999' : '#FFFFFF' }}>
                    <div style={{ marginLeft: '1em', fontSize: '90%' }}>
                  <div dangerouslySetInnerHTML={{ __html: '<br>' + record.description }}>
                  </div><br />
                  {record.duration > 0 &&
                   <div>For: {record.duration / 60} hours<br /></div>}
                  {record.rbac && record.rbac.includes('i') &&
                       !record.rbac.includes('j') && record.starts > Date.now() &&
                   <div><br /><i><b>Save the date</b> RSVPs are disabled</i></div>}
                <p /></div></div>
            )}
          </div>
    )
}

const EventCardTitle = record => {
    return <div>
        {record.status === 'canceled' && <div><b>Canceled</b></div>}
        On <Moment format="dddd, D MMM YYYY">{record.starts}
        </Moment> at <Moment format="h:mmA">{record.starts}</Moment>
    </div>
};

const CreateAlbumButton = ({ record }) => {
    return <Button component={Link} variant='contained'
        to={{
            pathname: '/album/create',
            state: { record: { event_id: record.id, name: 'pics-' + record.id } },
        }}>
        Add
    </Button>
}

const CreateGuestButton = ({ record }) => (
    <Button component={Link} variant='contained'
        to={{
            pathname: '/guest/create',
            state: { record: { event_id: record.id } },
        }}>
        Add
    </Button>
);

const EditGuestButton = ({ record, ...props }) => {
    // Is there a better way to pass these values than global vars??
    sessionStorage.setItem('rsvp_eid', record.event_id);
    return <Button component={Link} variant='contained' to='/guest/edit' >
        Reply
    </Button>
};

const CreateMessageButton = ({ record }) => {
    if (record.comments_status !== 'disabled') {
	return <Button component={Link} variant='contained'
	    to={{
		pathname: '/message/create',
		state: { record: { event_id: record.id } },
	    }}>
	    Post
	</Button>
    }
    else {
	return null
    }
};

const MessageDisplay = ({ id, record, resource }) => (
    <div dangerouslySetInnerHTML={{ __html: record.content }} />
);

const ListFilter = (props) => (
    <Filter {...props}>
	<BooleanInput label='Past Events' source='prev' alwaysOn />
        <ReferenceInput source='category_id' reference='category'>
          <SelectInput optionText='name' />
        </ReferenceInput>
        <AutocompleteInput source='privacy' choices={[
            { id: 'public', name: 'public' },
            { id: 'invitee', name: 'invitee' },
            { id: 'secret', name: 'secret' },
        ]} defaultValue='public' />
    </Filter>
);

export default eventCreate;
